import { useQuery } from '@tanstack/react-query'
import { connect } from 'react-redux'
import {
  Col,
  Container,
  Row
} from "reactstrap"
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader"
import { getHealthcareVisits } from '../../../supabase-api/healthcare_visits'
import { HcvForm } from '../shared/HcvForm'
import { HcvListTable } from '../shared/HcvListTable'

function HealthcareVisitsComprehensive({ profile, medical, dispatch }: any) {
  const { data: healthcareVisits } = useQuery({
    queryKey: ['healthcare_visits'],
    queryFn: () => getHealthcareVisits(profile.user.id),
    enabled: !!profile.user.id
  })

  return (
    <>
      <MedicalAdminHeader name="Healthcare Visits" parentName="Comprehensive" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <HcvForm
              subscriptionTier="comprehensive"
              formAction="create"
            />
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <HcvListTable
              subscriptionTier="comprehensive"
              items={healthcareVisits ?? []}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

const ConnectedHealthcareVisitsComprehensive = connect((state: any) => ({
  profile: state.profile,
  medical: state.medical
}))(HealthcareVisitsComprehensive)

export default ConnectedHealthcareVisitsComprehensive
