import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import MedicalAdminHeader from '../../../components/Headers/MedicalAdminHeader'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getHealthcareVisit } from '../../../supabase-api/healthcare_visits'
import { useState } from 'react'
import { HcvDetails } from '../shared/HcvDetails'
import { HcvForm } from '../shared/HcvForm'
import { formatHcvImprovedHealthPrompt } from '../../../prompts/healthcare-visit-improved-health'
import { notifyError } from '../../../utils/notify'
import { supabase } from '../../../lib/supabase'

export default function HealthcareVisitsAdvancedDetails() {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [aiPromptTitle, setAiPromptTitle] = useState<string>()
  const [aiResponse, setAiResponse] = useState<string>()
  const [isPrompting, setIsPrompting] = useState<boolean>(false)

  const { id: healthcareVisitId } = useParams<{ id: string }>()

  const { data: healthcareVisit } = useQuery({
    queryKey: ['healthcare_visits', healthcareVisitId],
    queryFn: () => getHealthcareVisit(healthcareVisitId),
  })

  if (!healthcareVisit) {
    return <div>Loading...</div>
  }

  async function getHealthRecommendations() {
    setAiResponse(undefined)
    setIsPrompting(true)
    const query = formatHcvImprovedHealthPrompt(healthcareVisit!)
    const { data, error } = await supabase.functions.invoke('prompt-ai', {
      body: { query },
    })
    if (error) {
      console.error("Error fetching health recommendations: ", error)
      notifyError("Error fetching health recommendations.")
      setIsPrompting(false)
      return
    }
    setAiResponse(data.response)
    setAiPromptTitle('Health improvement recommendations')
    setIsPrompting(false)
  }

  return (
    <>
      <MedicalAdminHeader name="Healthcare Visits" parentName="Advanced" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            {!isEditing &&
              <HcvDetails
                healthcareVisit={healthcareVisit}
                subscriptionTier="advanced"
                onEdit={() => setIsEditing(true)}
              />
            }
            {isEditing &&
              <HcvForm
                subscriptionTier="advanced"
                formAction="update"
                defaultValues={healthcareVisit}
                onCancelEdit={() => setIsEditing(false)}
              />
            }
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="mb-0 pb-2">
                <h3>YourHealth Life Insights</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="d-flex flex-wrap">
                      <Button color="primary" className="mb-2" onClick={getHealthRecommendations}>Are there things I can be doing to improve my health?</Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="p-3">
                    <div className="p-0 p-lg-4 rounded" style={{ backgroundColor: '#fcfcfc' }}>
                      {aiPromptTitle && !isPrompting && <h3 className="mb-4">{aiPromptTitle}</h3>}
                      {isPrompting &&
                        <div className="w-100 d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border mr-2"
                            role="status"
                            style={{
                              width: 20,
                              height: 20,
                            }}
                          />
                          <span>Loading...</span>
                        </div>
                      }
                      {!isPrompting && <div
                        style={{ maxWidth: '64ch' }}
                        dangerouslySetInnerHTML={{ __html: aiResponse || "" }}
                      />}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}