import Profile from "./views/admin/profile";
import Account from "./views/admin/account";
import MedicalCard from "./views/admin/cards/Medical.js";
import ChampionCard from "./views/admin/cards/Champion.js";
import NeighborCard from "./views/admin/cards/Neighbor.js";
import Contacts from "./views/admin/medical/Contacts.js";
import Conditions from "./views/admin/medical/Conditions.js";
import Prescriptions from "./views/admin/medical/Prescriptions.js";
import OTCMedications from "./views/admin/medical/OTCMedications.js";
import Allergies from "./views/admin/medical/Allergies.js";
import Immunizations from "./views/admin/medical/Immunizations.js";
import Links from "./views/admin/resources/Links.js";

import RecordsManagementStarter from "./views/records-management/starter/RecordsManagementStarter";
import RecordsManagementAdvanced from "./views/records-management/advanced/RecordsManagementAdvanced";
import RecordsManagementComprehensive from "./views/records-management/comprehensive/RecordsManagementComprehensive";
import MedicationManagementStarter from "./views/medication-management/starter/MedicationManagementStarter.js";
import MedicationManagementAdvanced from "./views/medication-management/advanced/MedicationManagementAdvanced";
import MedicationManagementDetailsAdvanced from "./views/medication-management/advanced/MedicationManagementDetailsAdvanced";
import MedicationManagementComprehensive from "./views/medication-management/comprehensive/MedicationManagementComprehensive";
import MedicationManagementDetailsComprehensive from "./views/medication-management/comprehensive/MedicationManagementDetailsComprehensive";
import HealthcareVisitsStarter from "./views/healthcare-visits/starter/HealthcareVisitsStarter";
import HealthcareVisitsStarterDetails from "./views/healthcare-visits/starter/HealthcareVisitsStarterDetails";
import HealthcareVisitsAdvanced from "./views/healthcare-visits/advanced/HealthcareVisitsAdvanced";
import HealthcareVisitsAdvancedDetails from "./views/healthcare-visits/advanced/HealthcareVisitsAdvancedDetails";
import HealthcareVisitsComprehensive from "./views/healthcare-visits/comprehensive/HealthcareVisitsComprehensive";
import HealthcareVisitsComprehensiveDetails from "./views/healthcare-visits/comprehensive/HealthcareVisitsComprehensiveDetails";
import EnhancedCommunicationsStarter from "./views/enhanced-communications/starter/EnhancedCommunicationsStarter";
import EnhancedCommunicationsAdvanced from "./views/enhanced-communications/advanced/EnhancedCommunicationsAdvanced";
import EnhancedCommunicationsComprehensive from "./views/enhanced-communications/comprehensive/EnhancedCommunicationsComprehensive";

const routes = [
  {
    collapse: true,
    admin: false,
    name: "Home",
    icon: "ni ni-shop text-primary",
    state: "homeCollapse",
    views: [
      {
        path: "/home",
        name: "My Profile",
        miniName: "",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/account",
        name: "My Account",
        miniName: "A",
        component: Account,
        layout: "/admin",
      }
      // {
      //   path: "/subscription",
      //   name: "My Subscription",
      //   miniName: "S",
      //   component: Plan,
      //   layout: "/admin",
      // }
    ],
  },
  {
    collapse: true,
    admin: false,
    name: "Medical Information",
    icon: "ni ni-badge text-primary",
    state: "medicalCollapse",
    views: [
      {
        path: "/medical/contacts",
        name: "Contacts",
        miniName: "",
        component: Contacts,
        layout: "/admin",
      },
      {
        path: "/medical/conditions",
        name: "Conditions",
        miniName: "",
        component: Conditions,
        layout: "/admin",
      },
      {
        path: "/medical/prescriptions",
        name: "Prescriptions",
        miniName: "",
        component: Prescriptions,
        layout: "/admin",
      },
      {
        path: "/medical/otc",
        name: "OTC Medications",
        miniName: "",
        component: OTCMedications,
        layout: "/admin",
      },
      {
        path: "/medical/allergies",
        name: "Allergies",
        miniName: "",
        component: Allergies,
        layout: "/admin",
      },
      {
        path: "/medical/immunizations",
        name: "Immunizations",
        miniName: "",
        component: Immunizations,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    admin: false,
    name: "Essential Care Card",
    icon: "ni ni-ambulance text-primary",
    state: "cardsCollapse",
    views: [
      {
        path: "/cards/medical",
        name: "Medical",
        miniName: "E",
        component: MedicalCard,
        layout: "/admin",
      },
      {
        path: "/cards/champion",
        name: "Care Champion",
        miniName: "C",
        component: ChampionCard,
        layout: "/admin",
      },
      {
        path: "/cards/neighbor",
        name: "Neighbor",
        miniName: "C",
        component: NeighborCard,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    admin: false,
    name: "Resources",
    icon: "ni ni-briefcase-24 text-primary",
    state: "resourcesCollapse",
    views: [
      {
        path: "/resources/links",
        name: "Store Important Links",
        miniName: "L",
        component: Links,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    admin: true,
    beta: true,
    name: "Medication Management",
    icon: "fa fa-prescription-bottle text-primary",
    state: "medicationMgmtCollapse",
    views: [
      {
        path: "/medication-starter",
        name: "Starter",
        miniName: "S",
        component: MedicationManagementStarter,
        layout: "/medication-management",
      },
      {
        path: "/medication-advanced/:id",
        hidden: true,
        component: MedicationManagementDetailsAdvanced,
        layout: "/medication-management",
      },
      {
        path: "/medication-advanced",
        name: "Advanced",
        miniName: "A",
        component: MedicationManagementAdvanced,
        layout: "/medication-management",
      },
      {
        path: "/medication-comprehensive/:id",
        hidden: true,
        component: MedicationManagementDetailsComprehensive,
        layout: "/medication-management",
      },
      {
        path: "/medication-comprehensive",
        name: "Comprehensive",
        miniName: "C",
        component: MedicationManagementComprehensive,
        layout: "/medication-management",
      },
    ]
  },
  {
    collapse: true,
    admin: true,
    name: "Records Management",
    icon: "fa fa-folder-open text-primary",
    state: "recordsMgmtCollapse",
    views: [
      {
        path: "/records-starter",
        name: "Starter",
        miniName: "S",
        component: RecordsManagementStarter,
        layout: "/records-management",
      },
      {
        path: "/records-advanced",
        name: "Advanced",
        miniName: "A",
        component: RecordsManagementAdvanced,
        layout: "/records-management",
      },
      {
        path: "/records-comprehensive",
        name: "Comprehensive",
        miniName: "C",
        component: RecordsManagementComprehensive,
        layout: "/records-management",
      },
    ]
  },
  {
    collapse: true,
    admin: true,
    beta: true,
    name: "Healthcare Visits",
    icon: "fa fa-user-doctor text-primary",
    state: "healthcareVisitsCollapse",
    views: [
      {
        path: "/healthcare-starter/:id",
        hidden: true,
        component: HealthcareVisitsStarterDetails,
        layout: "/healthcare-visits",
      },
      {
        path: "/healthcare-starter",
        name: "Starter",
        miniName: "S",
        component: HealthcareVisitsStarter,
        layout: "/healthcare-visits",
      },
      {
        path: "/healthcare-advanced/:id",
        hidden: true,
        component: HealthcareVisitsAdvancedDetails,
        layout: "/healthcare-visits",
      },
      {
        path: "/healthcare-advanced",
        name: "Advanced",
        miniName: "A",
        component: HealthcareVisitsAdvanced,
        layout: "/healthcare-visits",
      },
      {
        path: "/healthcare-comprehensive/:id",
        hidden: true,
        component: HealthcareVisitsComprehensiveDetails,
        layout: "/healthcare-visits",
      },
      {
        path: "/healthcare-comprehensive",
        name: "Comprehensive",
        miniName: "C",
        component: HealthcareVisitsComprehensive,
        layout: "/healthcare-visits",
      },
    ]
  },
  {
    collapse: true,
    admin: true,
    name: "Enhanced Communications",
    icon: "fa fa-phone text-primary",
    state: "enhancedCommunicationsCollapse",
    views: [
      {
        path: "/comms-starter",
        name: "Starter",
        miniName: "S",
        component: EnhancedCommunicationsStarter,
        layout: "/enhanced-communications",
      },
      {
        path: "/comms-advanced",
        name: "Advanced",
        miniName: "A",
        component: EnhancedCommunicationsAdvanced,
        layout: "/enhanced-communications",
      },
      {
        path: "/comms-comprehensive",
        name: "Comprehensive",
        miniName: "C",
        component: EnhancedCommunicationsComprehensive,
        layout: "/enhanced-communications",
      },
    ]
  },
  // {
  //   collapse: true,
  //   admin: true,
  //   name: "Admin",
  //   icon: "ni ni-settings text-primary",
  //   state: "adminCollapse",
  //   views: [
  //     {
  //       path: "/users",
  //       name: "Users",
  //       miniName: "U",
  //       component: Users,
  //       layout: "/admin",
  //     }
  //   ],
  // },
];

export default routes;
