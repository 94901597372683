import PropTypes from "prop-types"
import React, { useState } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Button,
  Modal,
} from "reactstrap"
import { ModalFormHeader } from '../../components/ModalForms/ModalFormHeader'
import { ModalHeader } from './ModalHeader'

type Props = {
  serviceName: string
  subscriptionTier: string
  tutorialContent?: React.ReactNode
}
export function HeaderWithTutorial({ serviceName, subscriptionTier, tutorialContent }: Props) {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)

  return (
    <>
      <div className="header bg-info pb-6">
        <div className="mx-auto">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center px-0 py-2 py-md-4">
                <Col lg="12" xs="12" className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h2 className="h2 text-white d-inline-block mb-0">{serviceName}</h2>
                    <Breadcrumb
                      className="d-none d-md-inline-block ml-md-4"
                      listClassName="breadcrumb-links breadcrumb-dark"
                    >
                      <BreadcrumbItem>
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          <i className="fas fa-home" />
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          {subscriptionTier}
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem aria-current="page" className="active">
                        {serviceName}
                      </BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                  {tutorialContent &&
                    <>
                      {/* desktop help button */}
                      <Button
                        color="secondary"
                        className="d-none d-md-flex align-items-center px-4 py-2.5 mr-0"
                        onClick={() => setIsHelpModalOpen(true)}
                      >
                        <i className="fa-solid fa-circle-question mr-2" style={{ fontSize: "1.4rem" }} />
                        <span>Learn More</span>
                      </Button>

                      {/* mobile help button */}
                      <button
                        className="d-md-none bg-transparent border-0 p-0 mt-1"
                        onClick={() => setIsHelpModalOpen(true)}
                      >
                        <i className="fa-solid fa-circle-question text-white" style={{ fontSize: "1.75rem" }} />
                      </button>
                    </>
                  }
                </Col>
              </Row>
              <Row className="d-md-none py-2">
                <Col>
                  <h3 className="text-white" style={{ fontSize: 18 }}>{subscriptionTier}</h3>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        className="modal-dialog-centered"
        isOpen={isHelpModalOpen}
        toggle={() => setIsHelpModalOpen(false)}
        size="lg"
        style={{ maxWidth: '64ch' }}
      >
        <ModalHeader onClose={() => setIsHelpModalOpen(false)}>
          <h2>{serviceName}</h2>
          <h3>{subscriptionTier}</h3>
        </ModalHeader>
        {tutorialContent}
      </Modal>
    </>
  )
}
