import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import MedicalAdminHeader from '../../../components/Headers/MedicalAdminHeader'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getHealthcareVisit } from '../../../supabase-api/healthcare_visits'
import { useState } from 'react'
import { HcvDetails } from '../shared/HcvDetails'
import { HcvForm } from '../shared/HcvForm'

export default function HealthcareVisitsStarterDetails() {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { id: healthcareVisitId } = useParams<{ id: string }>()

  const { data: healthcareVisit } = useQuery({
    queryKey: ['healthcare_visits', healthcareVisitId],
    queryFn: () => getHealthcareVisit(healthcareVisitId),
  })

  if (!healthcareVisit) {
    return <div>Loading...</div>
  }

  async function getHealthRecommendations() {
    console.log('Getting health recommendations...')
  }

  return (
    <>
      <MedicalAdminHeader name="Healthcare Visits" parentName="Starter" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            {!isEditing &&
              <HcvDetails
                healthcareVisit={healthcareVisit}
                subscriptionTier="starter"
                onEdit={() => setIsEditing(true)}
              />
            }
            {isEditing &&
              <HcvForm
                subscriptionTier="starter"
                formAction="update"
                defaultValues={healthcareVisit}
                onCancelEdit={() => setIsEditing(false)}
              />
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}
