import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { supabase } from '../../../lib/supabase'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import { Database } from '../../../types'
import MedicalAdminHeader from '../../../components/Headers/MedicalAdminHeader'
import { notifyError, notifySuccess } from '../../../utils/notify'
import { connect } from 'react-redux'
import ReactDatetime from "react-datetime"
import { formatAlternativeMedicationsPrompt } from '../../../prompts/alternative-medications'
import { formatMedicationRecentResearchPrompt } from '../../../prompts/medication-recent-research'
import { formatMedicationContraindicationsPrompt } from '../../../prompts/medication-contraindications'
import { formatMedicationSideEffectsPrompt } from '../../../prompts/medication-side-effects'
import { HeaderWithTutorial } from '../../shared/HeaderWithTutorial'
import { RxMgmtModalTutorialComprehensive } from './RxMgmtModalTutorialComprehensive'

type Medication = Database['public']['Tables']['medication']['Row']
type Props = any
function MedicationManagementDetailsComprehensive({ profile, dispatch }: Props) {
  const { id: medicationId } = useParams<{ id: string }>()
  const [medication, setMedication] = useState<Medication>()
  const [formData, setFormData] = useState<Medication | any>()
  const [aiPromptTitle, setAiPromptTitle] = useState<string>()
  const [aiResponse, setAiResponse] = useState<string>()
  const [isPrompting, setIsPrompting] = useState<boolean>(false)
  const [allMedications, setAllMedications] = useState<string[]>([])

  useEffect(() => {
    if (!profile.user.id || !medicationId) return
    getPrescription(medicationId)
    getAllMedications()
  }, [profile.user.id, medicationId])

  if (!medication || !formData) return null

  const getAlternativeMedications = async () => {
    setAiResponse(undefined)
    setIsPrompting(true)
    const query = formatAlternativeMedicationsPrompt(medication.name)
    const { data, error } = await supabase.functions.invoke('prompt-ai', {
      body: { query },
    })
    if (error) {
      console.error("Error fetching alternative medications: ", error)
      notifyError("Error fetching alternative medications.")
      setIsPrompting(false)
      return
    }
    setAiResponse(data.response)
    setAiPromptTitle(`Alternative Medications to ${medication.name}`)
    setIsPrompting(false)
  }

  const getRecentResearch = async () => {
    setAiResponse(undefined)
    setIsPrompting(true)
    const query = formatMedicationRecentResearchPrompt(medication.name)
    const { data, error } = await supabase.functions.invoke('prompt-ai', {
      body: { query },
    })
    if (error) {
      console.error("Error fetching recent research: ", error)
      notifyError("Error fetching recent research.")
      setIsPrompting(false)
      return
    }
    setAiResponse(data.response)
    setAiPromptTitle(`Recent Research for ${medication.name}`)
    setIsPrompting(false)
  }

  const getContraindications = async () => {
    setAiResponse(undefined)
    setIsPrompting(true)
    const query = formatMedicationContraindicationsPrompt(medication.name, allMedications.filter(x => x !== medication.name))
    const { data, error } = await supabase.functions.invoke('prompt-ai', {
      body: { query },
    })
    if (error) {
      console.error("Error fetching contraindications: ", error)
      notifyError("Error fetching contraindications.")
      setIsPrompting(false)
      return
    }
    setAiResponse(data.response)
    setAiPromptTitle(`Potential Contraindications with ${medication.name}`)
    setIsPrompting(false)
  }

  const getSideEffects = async () => {
    setAiResponse(undefined)
    setIsPrompting(true)
    const query = formatMedicationSideEffectsPrompt(medication.name)
    const { data, error } = await supabase.functions.invoke('prompt-ai', {
      body: { query },
    })
    if (error) {
      console.error("Error fetching side effects: ", error)
      notifyError("Error fetching side effects.")
      setIsPrompting(false)
      return
    }
    setAiResponse(data.response)
    setAiPromptTitle(`Potential Side Effects when taking ${medication.name}`)
    setIsPrompting(false)
  }


  async function getPrescription(medicationId: string) {
    const { data, error } = await (supabase as any)
      .from('medication')
      .select(`*, medication_type!inner(*)`)
      .eq('medication_type.name', 'PRESCRIPTION')
      .eq('id', medicationId)
      .single()

    if (error) {
      console.error("Error fetching prescriptions: ", error)
    }

    setMedication(data)
    setFormData(data)
  }

  async function getAllMedications() {
    const { data, error } = await supabase
      .from('medication')
      .select('*')
      .eq('profile_id', profile.user.id)
    if (error || !data) {
      console.error("Error fetching medications: ", error)
      notifyError("Error fetching medications.")
      return
    }
    setAllMedications(data.map((medication) => medication.name))
  }

  async function savePrescription() {
    if (!medication) return
    if (!formData.name.trim()) {
      toast.error("Prescription name is required.")
      return
    }
    const { data, error } = await supabase
      .from('medication')
      .update({
        name: formData.name,
        dose: formData.dose,
        frequency: formData.frequency,
        date_started: formData.date_started,
        notes: formData.notes,
      })
      .eq('id', (medication as any).id)

    if (error) {
      console.error("Error updating medication: ", error)
      notifyError("Error updating medication")
      return
    }

    notifySuccess("Medication updated successfully")

    await getPrescription(medication.id)
  }

  return (
    <>
      <HeaderWithTutorial
        serviceName="Medication Management"
        subscriptionTier="Comprehensive"
        tutorialContent={<RxMgmtModalTutorialComprehensive />}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <div className="d-flex align-items-center">
                      <Link to="/medication-management/medication-comprehensive">
                        <Button
                          color="secondary"
                          style={{
                            fontSize: '.75rem',
                            marginRight: '1.5rem'
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <svg className="mr-2" style={{ width: 10, height: 10 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                            </svg>
                            Back
                          </div>
                        </Button>
                      </Link>
                      <h1 className="mb-0">{medication.name}</h1>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="order-xl-1" xl="12">

                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-4">Medication Details</h3>
                      </Col>
                    </Row>

                    <Form>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-prescription-name"
                              >
                                Name
                              </label>
                              <Input
                                id="input-prescription-name"
                                placeholder="Prescription name"
                                autoComplete="off"
                                type="text"
                                value={formData?.name}
                                onChange={e => setFormData({ ...formData, 'name': e.target.value })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-prescription-dose"
                              >
                                Dose
                              </label>
                              <Input
                                id="input-prescription-dose"
                                placeholder="Prescription dose"
                                type="text"
                                autoComplete="off"
                                value={formData?.dose}
                                onChange={e => setFormData({ ...formData, 'dose': e.target.value })}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-prescription-frequency"
                              >
                                Frequency
                              </label>
                              <Input
                                id="input-prescription-frequency"
                                placeholder="Prescription frequency"
                                autoComplete="off"
                                type="text"
                                value={formData?.frequency}
                                onChange={e => setFormData({ ...formData, 'frequency': e.target.value })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" xs="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-prescription-date"
                              >
                                Date Prescribed
                              </label>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: formData?.date_started,
                                }}
                                timeFormat={false}
                                onChange={date => {
                                  // Check if string (manual entry)
                                  if (typeof date == "string") {
                                    // Let module parse
                                  } else { // Moment Class
                                    // Set date
                                    setFormData({ ...formData, 'date_started': date.format('YYYY-MM-DD') })
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6" xs="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-notes"
                              >
                                Notes
                              </label>
                              <Input
                                id="input-notes"
                                rows="5"
                                placeholder="Prescription notes"
                                type="textarea"
                                value={formData?.notes}
                                onChange={e => setFormData({ ...formData, 'notes': e.target.value })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      <Row>
                        <Col xs="6">

                        </Col>
                        <Col className="text-right" xs="6">
                          <div style={{
                            height: '100%',
                            float: 'right'
                          }}>
                            <Button
                              color="primary"
                              style={{
                                height: (profile.isMobile) ? '64px' : '100%'
                              }}
                              onClick={savePrescription}
                              size="xl"
                              disabled={!formData.name}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="mb-0 pb-2">
                <h3>YourHealth Life Insights</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="d-flex flex-wrap">
                      <Button color="primary" className="mb-2" onClick={getAlternativeMedications}>Show Alternative Medications</Button>
                      <Button color="primary" className="mb-2" onClick={getRecentResearch}>Show Recent Research</Button>
                      <Button color="primary" className="mb-2" onClick={getContraindications}>Show Potential Contraindications</Button>
                      <Button color="primary" className="mb-2" onClick={getSideEffects}>Show Potential Side Effects</Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="p-3">
                    <div className="p-0 p-lg-4 rounded" style={{ backgroundColor: '#fcfcfc' }}>
                      {aiPromptTitle && !isPrompting && <h3 className="mb-4">{aiPromptTitle}</h3>}
                      {isPrompting &&
                        <div className="w-100 d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border mr-2"
                            role="status"
                            style={{
                              width: 20,
                              height: 20,
                            }}
                          />
                          <span>Loading...</span>
                        </div>
                      }
                      {!isPrompting && <div
                        style={{ maxWidth: '64ch' }}
                        dangerouslySetInnerHTML={{ __html: aiResponse || "" }}
                      />}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container >
    </>
  )
}

const ConnectedMedicationManagementDetailsComprehensive = connect((state: any) => ({
  profile: state.profile,
}))(MedicationManagementDetailsComprehensive)

export default ConnectedMedicationManagementDetailsComprehensive
