export function RxMgmtModalTutorialComprehensive() {
  return (
    <div className="modal-body pt-0">
      {/* title is derived from the serviceName and subscriptionTier props passed to the HeaderWithTutorial component */}
      <p>
        Before you begin using <span className="font-weight-bold">Medication Management Comprehensive</span>, make sure that you have
        completed your Starter and Advanced applications; it is free as part of your
        Comprehensive subscription.
      </p>
      <p>
        All the features and functionality in the Starter and Advanced Medication Management
        are available to use. The initial screen for Comprehensive looks very similar to the initial
        screen for Advanced.
      </p>
      <div className="mb-5" style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/medication-management/comprehensive/page.jpg"
          alt="Medication Management Comprehensive Page"
          className="img-fluid shadow rounded-sm"
        />
      </div>
      <p>
        However, Comprehensive unlocks some very exciting capabilities that you can access
        by selecting a medication.
      </p>
      <div className="mb-5" style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/medication-management/comprehensive/details.jpg"
          alt="Medication Management Comprehensive Detailed View"
          className="img-fluid shadow rounded-sm"
        />
      </div>
      <p>
        In addition to the functionality you unlocked in the Advanced tier – Alternative
        Medications and Recent Research – Comprehensive provides you with two very
        exciting capabilities.
      </p>
      <p>
        Select a medication and click on Show Potential Contraindications button. It will look at
        all the medications that you have listed, do the research and indicate if there are
        potential contraindications between that medication and all the others you have listed.
        This can be very important, especially if you have been prescribed medications from
        different sources.
      </p>
      <p>
        The next button, Show Potential Side Effects, takes a medication that you have
        selected, does the research for you, and indicates what are potential side affects you
        may experience in taking that selected medication.
      </p>
      <p>
        Remember to consult with your doctor any time you get information from YourHealth.
      </p>
    </div>
  )
}